import {
    CREATE_BETS,
    SUB_BET,
    SET_ADD_BET,
    SUB_ACTIVE_BETS,
    RESET_ACTIVE_BET,
    SET_ALERT_MSG,
    SET_ALL_BETS,
    SET_CUSTOM_DISABLED_PAIR,
    SET_USER_BALANCE,
    SEND_BET,
    SET_BETS,
    SET_DELETE_BETS_CONDITION,
    CHECKED_IS_ACTIVE_CHIP,
    RESET_SELECTED_BOARD,
    SET_SELECTED_BOARD,
    SET_LOAD_INFO,
    SET_DISABLE_BETS_AREAS
} from "@actions/actionTypes";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "@services/storageService";
import { getBetsList } from "@services/betService";


export function createBet(chip, prediction, odd) {
    return (dispatch, getState) => {
        const bets = { Prediction: prediction, Stake: +chip, Odd: odd};
        const { userInfo, roundId } = getState();
        if (userInfo && roundId) {
            const unAcceptedBets = getLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`) || [];
            const acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
            const betsList = getBetsList(bets, unAcceptedBets)

            setLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`, betsList)
            dispatch({
                type: CREATE_BETS, bets: [...betsList, ...acceptedBets]
            })
        }
    }

}

export function addBet() {
    return (dispatch, getState) => {
        const { userInfo, roundId } = getState();
        const filteredBet = [];
        let bets = getLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`) || []
        bets.forEach(item => {
            filteredBet.push({
                Prediction: item.Prediction,
                Stake: item.Stake
            })
        })

        if (filteredBet.length) {
            dispatch({
                eventEmit: SEND_BET,
                emitData: [
                    ...filteredBet
                ],
                type: SEND_BET
            })
        }
    }
}

export function subscribeBetResult() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_BET,
            handle: (data) => {
                const { userInfo, roundId } = getState();
                let acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
                dispatch({ type: SET_USER_BALANCE, balance: data.Balance });
                // dispatch({type: SET_LOAD_INFO, load: data.Load || initialStore.loadInfo})
                if (Array.isArray(data.Bets)) {
                    var userBookData = [];
                    let bets = data.Bets.filter(bet => !bet.ErrorCode).map(item => {
                        return {
                            accepted: true,
                            Prediction: item.Prediction,
                            Stake: item.Stake,
                            Odd: item.Odd
                        }
                    })
                    bets.forEach(item => {
                        userBookData['prediction-'+item.Prediction] = item.Stake;
                    })
                    dispatch({type: SET_LOAD_INFO, load: userBookData || initialStore.loadInfo})
                    removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`);
                    setLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`, [...acceptedBets, ...bets])
                    dispatch({ type: SET_BETS, bets: [...acceptedBets] });
                    data.Bets?.forEach(bet => {
                        if (bet.ErrorCode) {
                            dispatch({ type: SET_ALERT_MSG, data: { type: 'error', messages: bet.Message } });
                        }
                    })
                }
            }
        })
    }
}

export function subscribeActiveBets() {
    return (dispatch, getState) => {
        dispatch({
            eventOn: SUB_ACTIVE_BETS,
            handle: (data) => {
                if (!data.ErrorCode) {
                    dispatch({ type: SET_ALL_BETS, bets: data.Bets, startTime: getState().timeTicker.start });
                }

            }
        })
    }
}

export function setPair(name) {
    return dispatch => {
        dispatch({ type: SET_CUSTOM_DISABLED_PAIR, name });
    }
}

export function resetBets() {
    return (dispatch, getState) => {
        const {userInfo, roundId} = getState();
        let acceptedBets = getLocalStorage(`acceptedBets_${userInfo.Id}_${roundId}`) || [];
        removeLocalStorage(`unAcceptedBets_${userInfo.Id}_${roundId}`)
        dispatch({type: SET_ALL_BETS, bets: acceptedBets})
    }
}

export function resetSelectBoard() {
    return dispatch => {
        dispatch({ type: RESET_SELECTED_BOARD })
    }
}

export function selectBoard(board, odd) {
    return dispatch => {
        dispatch({ type: SET_SELECTED_BOARD, board, odd })
    }
}

export function setBordActive(isEnabled) {
    return dispatch => {
        dispatch({type: SET_DISABLE_BETS_AREAS, isEnabled: isEnabled})
    }
}