import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
    addBet,
    subscribeBetResult,
    subscribeActiveBets,
    selectBoard,
} from "@actions/betsAction";
import { connect } from "react-redux";

import SmallBoard from "./BoardElements/SmallBoard";
import LargeBoard from "./BoardElements/LargeBoard";
import MediumBoard from "./BoardElements/MediumBoard";
import {
    subscribeTickerWorker,
    unSubscribeTickerWorker,
} from "@actions/workerActions";

function BetArea({
    threeD,
    subscribeBetResult,
    subscribeActiveBets,
    enabledBetAreas,
    odd,
    selectBoard,
    selectedBoard,
    subscribeTickerWorker,
    unSubscribeTickerWorker,
    loadInfo
}) {
    const [disabledAll, setDisabledAll] = useState(true);

    useEffect(() => {
        function getGameTimeDiff(e) {
            const timer = JSON.parse(e.data);
            if (!(timer.data && parseInt(timer.data / 1000) > 3)) {
                setDisabledAll(true);
            } else {
                setDisabledAll(false);
            }
        }
        subscribeTickerWorker(getGameTimeDiff);
        return () => {
            unSubscribeTickerWorker(getGameTimeDiff);
        };
    }, []);

    useEffect(() => {
        subscribeBetResult();
        subscribeActiveBets();
    }, []);

    return (
        <div
            className={classNames("bet-area", {
                threeD,
                "enabled-bet-areas": enabledBetAreas,
            })}
        >
            <div className="board-body">
                <SmallBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard}
                    disabledAll={disabledAll}
                />
                <MediumBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard}
                    disabledAll={disabledAll}
                />
                <LargeBoard
                    odd={odd}
                    selectBoard={selectBoard}
                    selectedBoard={selectedBoard}
                    disabledAll={disabledAll}
                />
            </div>
            <div className="book-show-top">
                <div className={`${loadInfo['prediction-5'] > 0 ? 'perfect-pair-book green' : ''}`}>{loadInfo['prediction-5'] > 0 ? loadInfo['prediction-5'] : ''}</div>
                <div className={`${loadInfo['prediction-6'] > 0 ? 'either-pair-book green' : ''}`}>{loadInfo['prediction-6'] > 0 ? loadInfo['prediction-6'] : ''}</div>
            </div>
            <div className="book-show-bottom">
                <div className={`${loadInfo['prediction-1'] > 0 ? 'player-book green' : ''}`}>{loadInfo['prediction-1'] > 0 ? loadInfo['prediction-1'] : ''}</div>
                <div className={`${loadInfo['prediction-0'] > 0 ? 'banker-book green' : ''}`}>{loadInfo['prediction-0'] > 0 ? loadInfo['prediction-0'] : ''}</div>
                <div className={`${loadInfo['prediction-2'] > 0 ? 'tie-book green' : ''}`}>{loadInfo['prediction-2'] > 0 ? loadInfo['prediction-2'] : ''}</div>
                <div className={`${loadInfo['prediction-4'] > 0 ? 'player-pair-book green' : ''}`}>{loadInfo['prediction-4'] > 0 ? loadInfo['prediction-4'] : ''}</div>
                <div className={`${loadInfo['prediction-3'] > 0 ? 'banker-pair-book green' : ''}`}>{loadInfo['prediction-3'] > 0 ? loadInfo['prediction-3'] : ''}</div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        enabledBetAreas: state.enabledBetAreas,
        odd: state.odd,
        selectedBoard: state.selectedBoard,
        loadInfo: state.loadInfo
    };
}

const mapDispatchToProps = {
    subscribeBetResult,
    subscribeActiveBets,
    unSubscribeTickerWorker,
    subscribeTickerWorker,
    selectBoard,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetArea);
