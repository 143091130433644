import {
    SET_PLAYER_CARDS,
    SUB_CARD,
    SET_BANKER_CARDS,
    SET_SOUND_KEY
} from "@actions/actionTypes";
import initialStore from "../initialStore";
import {CardsSuit, CardTypeMap} from "@constants/Enums";

export function subscribeCards() {
    return dispatch => {
        dispatch({
            eventOn: SUB_CARD,
            handle: (data) => {
                if(data.BankerCards.length || data.PlayerCards.length) {
                    const lastCard = data.BankerCards.length >= data.PlayerCards.length ? data.BankerCards[data.BankerCards.length - 1] : data.PlayerCards[data.PlayerCards.length - 1];
                    dispatch({type: SET_SOUND_KEY, payload:  CardTypeMap[lastCard.Type] + CardsSuit[lastCard.Suit]})
                }
                dispatch({type: SET_BANKER_CARDS, cards: data.BankerCards, cardsWeight: data.BankerCardsWeight})
                dispatch({type: SET_PLAYER_CARDS, cards: data.PlayerCards, cardsWeight: data.PlayerCardsWeight})
            }
        })
    }
}

export function resetPlayingCards() {
    return dispatch => {
        dispatch({type: SET_BANKER_CARDS, ...initialStore.cards})
        dispatch({type: SET_PLAYER_CARDS, ...initialStore.cards})
    }
}