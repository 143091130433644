//lang
export const SET_AVAILABLE_LANGUAGES = "SET_AVAILABLE_LANGUAGES"
export const REQUEST_AVAILABLE_LANGUAGES = "REQUEST_AVAILABLE_LANGUAGES"
export const SET_SELECTED_LANGUAGE_MESSAGE = "SET_SELECTED_LANGUAGE_MESSAGE"
export const REQUEST_SELECTED_LANGUAGE_MESSAGE = "REQUEST_SELECTED_LANGUAGE_MESSAGE"

//error
export const SET_ALERT_MSG = "SET_ALERT_MSG"
export const RESET_ALERT_MSG = "RESET_ALERT_MSG"

// bets
export const SET_SELECTED_CHIP = "SET_SELECTED_CHIP"
export const CHECKED_IS_ACTIVE_CHIP = "CHECKED_IS_ACTIVE_CHIP"
export const SET_ADD_BET = "SET_ADD_BET"
export const RESET_USER_BET_LIST = "RESET_USER_BET_LIST"
export const RESET_ACTIVE_BET = "RESET_ACTIVE_BET"
export const SET_DISABLED_BETS_AREA = "SET_DISABLED_BETS_AREA"
export const SET_SELECTED_BOARD = "SET_SELECTED_BOARD"
export const RESET_SELECTED_BOARD = "RESET_SELECTED_BOARD"
export const CREATE_BETS = "CREATE_BETS"
export const SET_DISABLE_BETS_AREAS = "SET_DISABLE_BETS_AREAS"


//winnerList
export const SET_WINNER_LIST = "SET_WINNER_LIST"

//history
export const SET_HISTORY = "SET_HISTORY"
export const RESET_HISTORY = "RESET_HISTORY"
export const REQUEST_BET_HISTORY = "REQUEST_BET_HISTORY"
export const SET_BET_HISTORY = "SET_BET_HISTORY"


//viewSettings
export const SET_VIEW_SETTINGS = "SET_VIEW_SETTINGS"

//user
export const SET_USER_SESSION = "SET_USER_SESSION"
export const SET_SOCKET_CONNECTIONS = "SET_SOCKET_CONNECTIONS"
export const SET_USER_INFO ="SET_USER_INFO"
export const SET_USER_BALANCE = "SET_USER_BALANCE"
export const SET_LOGIN_DETECTED = "SET_LOGIN_DETECTED"
export const SET_USER_WON = "SET_USER_WON"

// rules
export const REQUEST_RULES = "REQUEST_RULES"
export const SET_RULES = "SET_RULES"

// sounds/music
export const SET_BACKGROUND_MUSIC = "SET_BACKGROUND_MUSIC"
export const SET_SOUND_ON_OFF = "SET_SOUND_ON_OFF"
export const SET_SOUND_KEY = "SET_SOUND_KEY"
export const SET_VOLUME = "SET_VOLUME"

// game
export const SET_ROUND_START = "SET_ROUND_START"
export const SET_TIME_TICKER = "SET_TIME_TICKER"
export const SET_ODDS = "SET_ODDS"
export const SET_WINNER = "SET_WINNER"
export const SET_SERVER_TIME = "SET_SERVER_TIME"
export const SET_GAME_TICKER_LIMIT = "SET_GAME_TICKER_LIMIT"
export const SET_VIDEO_STREAM_CONFIG = "SET_VIDEO_STREAM_CONFIG"
export const SET_GAME_CONDITION = "SET_GAME_CONDITION"
export const SET_ALL_BETS = "SET_ALL_BETS"
export const SET_GAME_HISTORY = "SET_GAME_HISTORY"
export const SET_CUSTOM_DISABLED_PAIR = "SET_CUSTOM_DISABLED_PAIR"
export const REQUEST_REFRESH_SESSION = "REQUEST_REFRESH_SESSION"
export const REQUEST_GAME_START = "REQUEST_GAME_START"
export const SET_GAME_START = "SET_GAME_START"
export const CONNECT_TO_SOCKET ="CONNECT_TO_SOCKET"
export const SET_GAME_INITIAL_DATA = "SET_GAME_INITIAL_DATA"
export const SET_LOCAL_BETS = "SET_LOCAL_BETS"
export const SET_BETS = "SET_BETS"
export const SET_DELETE_BETS_CONDITION = "SET_DELETE_BETS_CONDITION"
export const SET_PLAYER_CARDS = "SET_PLAYER_CARDS"
export const SET_BANKER_CARDS = "SET_BANKER_CARDS"
export const SET_LOAD_INFO = "SET_LOAD_INFO"
// limit
export const SET_LIMIT = "SET_LIMIT"

//worker
export const SET_SUB_TICKER_WORKER = "SET_SUB_TICKER_WORKER"
export const SET_UN_SUB_TICKER_WORKER = "SET_UN_SUB_TICKER_WORKER"
// inactivity
export const SET_INACTIVITY = "SET_INACTIVITY"

// socket
export const SUB_SERVER_TIME = "0"
export const SUB_ERROR = "1"
export const SUB_ROUND_START = "2"
export const SUB_CARD = "3"
export const SUB_ODD = "4"
export const SUB_TIME_TICKER = "5"
export const SUB_GAME_FINISH = "6"
export const SUB_BET = "7"
export const SUB_PAIRS = "8"
export const SUB_BET_HISTORY = "9"
export const SUB_WINNER_LIST = "10"
export const SUB_ACTIVE_BETS = "11"
export const SUB_VIDEO_STREAM = "12"
export const SUB_USER_INFO = "13"
export const SUB_LOAD = "14"
export const SUB_CANCEL_GAME = "15"
export const SUB_GAME_HISTORY = "16"
export const SUB_BALANCE_UPDATE = "17"
export const SUB_GAME_INIT = "18"
export const SUB_GAME_RESULT = "19"

export const SEND_PING = "0"
export const SEND_BET = "1"
export const SEND_BET_HISTORY = "2"
export const SEND_TOTAL_BETS = "3"
export const SEND_USER_INFO = "4"
export const SEND_GAME_HISTORY ="5"


export const PONG_TYPE = "0"