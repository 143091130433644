import React from "react";
import CardListView from "@components/cards/CardsList";
import classNames from "classnames";

function CardList({points, player, cards, winner = false,}) {
    return (
        <div className={classNames("history-card-list", { active: winner})}>
            <div>
                <div className="point">{ points }</div>
                <div className="player">{ player }</div>
            </div>
            <div>
                <CardListView cardList={cards} className="history-card-list-view"/>
            </div>
        </div>
    )
}

export default CardList